import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {emptyUrlParameters} from '../../utils/constants'

export const emptyFeedbackUrlParameters: IUrlParameters = {
  ...emptyUrlParameters,
  url: '/feedback/answers/',
}

type accaunt = {
  id: number
  company: string
  name: string
}

export type generateAccaunt = {
  id: number
  name: string
}

type marketplace = {
  code: string
  color: string
}

type sku = {
  customer_uid: string
  nmid?: string
  name: string
}

type TImage = {
  fullSize: string
  miniSize: string
}

type TVideo = {
  durationSec: number
  link: string
  previewImage: string
}

export interface IFeedback {
  id: number
  account: accaunt
  answer: string
  author: string
  created_date: string
  feedback_id: string
  feedback_status: string
  feedback_text: string
  images: TImage[]
  marketplace: marketplace
  order_id: string
  videos: TVideo[]
  name: string
  order_status: string
  rate: number
  sku: sku
}

export interface IFeedbackResponse {
  count: number
  next: string | null
  previous: string | null
  results: IFeedback[]
}

export interface IFeedbackSendParams {
  text: string
  account: number
  feedback_code: string
  name: string | null
}
export interface IFeedbackRequestParams {
  good_name: string
  category: string | null
  rating: string
  text: string | null
  name: string | null
}

export const emptyFeedback: IFeedbackRequestParams = {
  good_name: '',
  category: '',
  rating: '',
  text: '',
  name: '',
}

export interface IFeedbackGenerateSettings {
  network_role: string | undefined | null
  accounts: generateAccaunt[] | []
  contacts: string | undefined | null
  ai_text: string | undefined | null
  use_name: boolean
  min_rate: number
  rates_answer: string[]
  rates_notification: string[]
  new_feedbacks_notification: boolean
  feedbacks_answers_notification: boolean
}

export const emptyFeedbackGenerateSettings: IFeedbackGenerateSettings = {
  network_role: '',
  accounts: [],
  contacts: '',
  ai_text: '',
  use_name: true,
  min_rate: 1,
  rates_answer: ['1', '2', '3', '4', '5', 'all'],
  rates_notification: ['1', '2', '3', '4', '5', 'all'],
  new_feedbacks_notification: false,
  feedbacks_answers_notification: false,
}

export interface IFeedbackState {
  generatedFeedback: string
  isFeedbackLoading: boolean
  isFilteredItemsLoading: boolean
  isGenerated: boolean
  isLoadingSelect: boolean
  isSendLoading: boolean
  sendResult: boolean
  items: IFeedback[]
  selected: IFeedback | null
  count: number
  next: string | null
  previous: string | null
  filters: IUrlParameters
  isShowFeedbackSettingsModal: boolean
  feedbacksSettingsIsLoading: boolean
  feedbackGenerateSettings: IFeedbackGenerateSettings
}

export const emptyState: IFeedbackState = {
  generatedFeedback: '',
  isFeedbackLoading: false,
  isFilteredItemsLoading: false,
  isGenerated: false,
  isLoadingSelect: false,
  isSendLoading: false,
  sendResult: false,
  items: [],
  selected: null,
  count: 0,
  next: null,
  previous: null,
  filters: emptyFeedbackUrlParameters,
  isShowFeedbackSettingsModal: false,
  feedbacksSettingsIsLoading: true,
  feedbackGenerateSettings: emptyFeedbackGenerateSettings,
}

export enum FeedbackQuickMenuItem {
  AnswerGenerator = 'AnswerGenerator',
  Answers = 'Answers',
}

export enum FeedbacksActionTypes {
  FetchFeedbacks = 'FetchFeedbacks',
  FetchFeedbackById = 'FetchFeedbackById',
  SET_Feedback = 'SET_Feedback',
  SEND_Feedback = 'SEND_Feedback',
  FetchSettings = 'FetchSettings',
}
