import {FC} from 'react'
import {default as ReactPhoneInput, PhoneInputProps} from 'react-phone-input-2'

interface IPhoneInputProps {
  onChange: (value: string) => void
  validator?: (inputNumber: string, country: any, countries: object[]) => boolean
  label?: string
  hasErrors?: boolean
  errors?: string
  wrapperClassName?: string
  inputProps?: PhoneInputProps
}

const PhoneInput: FC<IPhoneInputProps> = ({
  label,
  wrapperClassName,
  onChange,
  hasErrors,
  errors,
  inputProps,
  validator,
}) => {
  return (
    <div className={wrapperClassName}>
      {label && (
        <label className='form-label fs-6 text-gray-700' style={{fontWeight: 'bold'}}>
          {label}
        </label>
      )}

      <ReactPhoneInput
        onChange={onChange}
        isValid={validator ? validator : undefined}
        {...inputProps}
      />

      {hasErrors && (
        <div className='fv-plugins-message-container text-danger'>
          <span role='alert'>{errors}</span>
        </div>
      )}
    </div>
  )
}

export default PhoneInput
