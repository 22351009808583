import {createAction} from '@reduxjs/toolkit'
import {InvoicesActionTypes, IPaymentRequestParams, IPaymentRequestOptionalParams} from './types'

export const FETCH_INVOICES = InvoicesActionTypes.FetchInvoices
export const CREATE_PAYMENT_DOCUMENT = InvoicesActionTypes.CreatePaymentDocument
export const CREATE_PAYMENT_OPTIONAL_DOCUMENT = InvoicesActionTypes.CreatePaymentOtionalDocument

export const fetchInvoicesAction = createAction(FETCH_INVOICES)
export const createPaymentDocumentAction =
  createAction<IPaymentRequestParams>(CREATE_PAYMENT_DOCUMENT)
export const createPaymentDocumentOptionalAction =
  createAction<IPaymentRequestOptionalParams>(CREATE_PAYMENT_DOCUMENT)
