import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {DEFAULT_PRICES_GET_LIMIT, DEFAULT_PRICES_OFFSET} from '../goodsGroups/constants'
import axios from 'axios'
import {IInvoiceState, IPaymentRequestParams, IPaymentRequestOptionalParams} from './types'
import {blobUtils} from '../../utils/dataUtils'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`

export const INVOICES_DATA_URL = `${API_URL}v2/invoices/`

export const fetchInvoices = (params: IUrlParameters) => {
  const offset =
    params.pageNumber !== undefined && params.itemsPerPage !== undefined
      ? params.pageNumber * params.itemsPerPage
      : DEFAULT_PRICES_OFFSET

  return axios.get<IInvoiceState>(
    `${INVOICES_DATA_URL}?limit=${params.itemsPerPage ?? DEFAULT_PRICES_GET_LIMIT}&offset=${offset}`
  )
}

export const createNewPaymentDocument = (data: IPaymentRequestParams) => {
  return axios.post(`${INVOICES_DATA_URL}deposit_balance_from_e_invoice/`, data, {
    responseType: 'blob',
  })
}

export const createNewPaymentOptionalDocument = (data: IPaymentRequestOptionalParams) => {
  // return axios.post(`${INVOICES_DATA_URL}deposit_balance_from_e_invoice/`, data, {
  //   responseType: 'blob',
  // })
  return axios
    .post(
      `${API_URL}invoices/renew_subscription_from_e_invoice/`,
      {
        amount: data.amount,
        payer: data.customerCompanyId,
      },
      {responseType: 'blob'}
    )
    .catch(async (response) => {
      const err = await blobUtils.readBlobAsText(response.response.data)
      const error: any = JSON.parse(err as string)
      // eslint-disable-next-line no-throw-literal
      throw {response: {data: error}}
    })
}
